<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card card-body">
            <p>المحصلين</p>
            <div class="form-group">
            <label for="">اسم المحصل</label>
            <input type="text"
                class="form-control" v-model="name">
            </div>
            <div class="form-group">
            <label for="">اسم المستخدم</label>
            <input type="text"
                class="form-control" v-model="username">
            </div>
            <div class="form-group">
            <label for="">الهاتف</label>
            <input type="text"
                class="form-control" v-model="phone">
            </div>
            <div class="form-group">
            <label for="">كلمة المرور</label>
            <input type="text"
                class="form-control" v-model="password">
            </div>
            <div class="form-group">
              <label for="">الفنادق المسموح بها</label>
              <select class="form-control" name="" id="" multiple v-model="selected_hotels">
                <option v-for="hotel in hotels" :key="hotel._id" :value="hotel._id">{{ hotel.title }}</option>
              </select>
            </div>
            <div class="form-group">
            <label for="">ملاحظات</label>
            <textarea class="form-control" v-model="notes" id="" rows="3"></textarea>
            </div>
            <div class="col-12 text-center g" @click="addItem()">
                <button class="btn btn-primary">
                    <i class="fa fa-save"></i>
                    اضافة
                </button>
            </div>
            <div class="col-12" v-if="error">
                <div class="alert alert-danger text-center g">
                    <h4>
                        {{ error }}
                    </h4>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 g">
        <div class="card card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>
                            الاسم
                        </th>
                        <th>
                            اسم المستخدم
                        </th>
                        <th>
                            الهاتف
                        </th>
                        <th>
                            كلمة المرور 
                        </th>
                        <th>
                            الفنادق المسموحة
                        </th>
                        <th>
                            ملاحظات
                        </th>
                        <th>
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="item in arr" :key="item._id">
                            <td>
                                <input type="text" v-model="item.name" style="border:none" @change="editItem(item._id, item)">
                            </td>
                            <td>
                                {{ item.username }}
                            </td>
                            <td>
                                <input type="text" v-model="item.phone" style="border:none" @change="editItem(item._id, item)">
                            </td>
                            <td>
                                <input type="text" v-model="item.password" v-if="changepass" style="border:none" @change="editItem(item._id, item)">
                                <span @click="changepass = !changepass">تعديل <i class="fa fa-key"></i></span>
                            </td>
                            <td>
                                <select class="form-control" name="" id="" multiple v-model="item.hotels" @change="editItem(item._id, item)">
                                    <option v-for="hotel in hotels" :key="hotel._id" :value="hotel._id">{{ hotel.title }}</option>
                                </select>
                            </td>
                            <td>
                                <input type="text" v-model="item.notes" style="border:none" @change="editItem(item._id, item)">
                            </td>
                            <td>
                                <button class="btn btn-sm text-danger fa fa-trash" @click="deleteItem(item._id)"></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            arr: [],
            error: "",
            name: "",
            phone: "",
            username: "",
            password: "",
            notes: "",
            changepass: false,
            hotels: [],
            selected_hotels: []
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/cashs/all', {
            jwt: this.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                if(r.response){
                    g.arr = r.response
                }
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
        $.post(api + '/admin/hotels/all', {
            jwt: this.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                if(r.response){
                    g.hotels = r.response
                }
            }
        }).fail(function(){
            alert("حدث مشكلة في الاتصال")
        })
    },
    methods: {
        editItem(id, item){
            var g = this;
            $.post(api + '/admin/cashs/edit', {
                    jwt: this.jwt,
                    id: id,
                    name: item.name,
                    username: item.username,
                    phone: item.phone,
                    password: item.password,
                    notes: item.password,
                    hotels: item.hotels,
                }).then(function(r){
                    if(r.status != 100){
                        g.error = r.response
                    }
                }).fail(function(){
                    g.error = "حدث مشكلة في الاتصال"
                })
        },
        addItem(){
            var g =this;
            if(g.username && g.username != ""){
                $.post(api + '/admin/cashs/add', {
                    jwt: this.jwt,
                    name: this.name,
                    username: this.username,
                    phone: this.phone,
                    password: this.password,
                    notes: this.password,
                    hotels: this.selected_hotels
                }).then(function(r){
                    if(r.status != 100){
                        g.error = r.response
                    }else{
                        location.reload()
                    }
                }).fail(function(){
                    g.error = "حدث مشكلة في الاتصال"
                })
            }
        },
        deleteItem(id){
            var g = this;
            if(confirm("متأكد من حذف الوكيل نهائياً؟")){
                $.post(api + '/admin/cashs/delete', {
                    jwt: this.jwt,
                    id: id
                }).then(function(r){
                    if(r.status != 100){
                        g.error = r.response
                    }else{
                        location.reload()
                    }
                }).fail(function(){
                    g.error = "حدث مشكلة في الاتصال"
                })
            }
        }
    },
}
</script>

<style>

</style>